<script setup></script>

<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>-->
  <RouterView/>
</template>

<style scoped>

</style>
