export default class PermissionService {

    async get(idUser) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/permission/user/' + idUser);
        return response.json();
    }

    async addPermissions(data) {

        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/permission',
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data),
            });

        return response.json();
    }
}