export default class NoteEntryService {

    getCodeGeneric() {
        return fetch(process.env.VUE_APP_API_IOT + '/v1/note-entry/code-generic')
            .then((res) => res.json())
        //.then((d) => d.Data);
    }

    async addNoteEntry(data) {

        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/note-entry',
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data),
            });

        return response.json();
    }
}