export default class ProductService {

    async getProductsByBranchOffice(idBranchOffice) {
        const res = await fetch(process.env.VUE_APP_API_IOT + '/v1/product/products-by-branchoffice/' + idBranchOffice);
        return await res.json();
    }

    async getProductsById(idProduct, idBranchOffice) {
        const res = await fetch(process.env.VUE_APP_API_IOT + '/v1/product/product-by-id/' + idProduct + '/' + idBranchOffice);
        return await res.json();
    }

    async create(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/product', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async update(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/product', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async delete(idProduct, idUserAction) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/product/' + idProduct + '/' + idUserAction, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });

        return response.json();
    }
}