<template>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <Toast />
          <ConfirmDialog></ConfirmDialog>
          <div v-if="isLoadingScreen">
            <div class="progress-spinner-custom">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
          </div>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="deleteSelected"
                  :disabled="!selectedItems || !selectedItems.length" />
              </div>
            </template>
  
            <template v-slot:end>
              <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
            </template>
          </Toolbar>
  
          <DataTable ref="dt" :value="unitMeasureList" v-model:selection="selectedItems" dataKey="IdUnitMeasure" :paginator="true"
            :rows="10" :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100]"
            currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} unidades de medida" responsiveLayout="scroll"
            :loading="isLoadingTable"
            class="p-datatable-sm">
  
            <template #header>
              <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 class="m-0">Unidades de Medida</h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                </span>
              </div>
            </template>
  
            <Column selectionMode="multiple" headerStyle="width: 5rem"></Column>
            <Column field="Description" header="Nombre" :sortable="true" headerStyle="min-width:16rem;">
              <template #body="slotProps">
                <span class="p-column-title">Nombre</span>
                {{ slotProps.data.Description }}
              </template>
            </Column>
            <Column field="Abbreviation" header="Abreviatura" :sortable="true" headerStyle="min-width:8rem;">
              <template #body="slotProps">
                <span class="p-column-title">Abreviatura</span>
                {{ slotProps.data.Abbreviation }}
              </template>
            </Column>
            <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                  @click="onEdit(slotProps.data)" />
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                  @click="deleteIndividual(slotProps.data)" />
              </template>
            </Column> 
          </DataTable>
  
          <Dialog v-model:visible="isUnitMeasureDialogOpen" :style="{ width: '480px' }" header="Detalle de la Unidad de Medida" :modal="true"
            class="p-fluid">
            <div class="field">
              <label for="description">Nombre</label>
              <InputText id="description" v-model.trim="unitMeasureModel.Description" :maxlength="50" required="true" autofocus autocomplete="off"
                :class="{ 'p-invalid': submitted && !unitMeasureModel.Description }" />
              <small class="p-invalid" v-if="submitted && !unitMeasureModel.Description">Nombre de la unidad de medida es requerida.</small>
            </div>
            <div class="field">
              <label for="name">Abreviatura</label>
              <InputText id="name" v-model.trim="unitMeasureModel.Abbreviation" :maxlength="20" required="false" autofocus autocomplete="off"/>
            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
              <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="onSave" />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </template>
    
  <script setup>
  import { FilterMatchMode } from 'primevue/api';
  import { ref, onMounted, onBeforeMount } from 'vue';
  import UnitMeasureService from '@/service/UnitMeasureService' ;
  import { useToast } from 'primevue/usetoast';
  import { useConfirm } from "primevue/useconfirm";
  import { useStore } from "vuex";

  const store = useStore();
  const confirm = useConfirm();
  const toast = useToast();
  
  const unitMeasureList = ref(null);
  const isUnitMeasureDialogOpen = ref(false);
  const isLoadingTable = ref(false);
  const unitMeasureModel = ref({});
  
  const selectedItems = ref(null);
  const dt = ref(null);
  const filters = ref({});
  const submitted = ref(false);
  const isLoadingScreen = ref(false);
  
  const unitMeasureService = new UnitMeasureService();
 
  onBeforeMount(() => {
    initFilters();
  });
  onMounted(() => {
    loadData();
  });
  
  const loadGridList = () => {
    isLoadingTable.value = true;
    unitMeasureService.getUnitMeasureAll().then(response => {
      unitMeasureList.value = response.Data;
      isLoadingTable.value = false;
    });
  }
  
  const loadData = () => {
    loadGridList();
  }
  
  const openNew = () => {
    unitMeasureModel.value = {};
    submitted.value = false;
    isUnitMeasureDialogOpen.value = true;
  };
  
  const hideDialog = () => {
    isUnitMeasureDialogOpen.value = false;
    submitted.value = false;
    unitMeasureModel.value = {};
  };
  
  const onSave = () => {
    submitted.value = true;
    const { ...model } = { ...unitMeasureModel.value };
    if(!model?.Description){
      toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor ingrese el nombre de la unidad de medida.", life: 3000 });
      return;
    }
    const dUser = JSON.parse(store.state.datauser);
    const request = { 
      CreationUser: dUser.User.IdUser, 
      Action: (model?.IdUnitMeasure?? 0) > 0 ? 'U' : 'N', 
      ...model
    };
  
    confirm.require({
      message: '¿Está seguro de guardar los cambios?',
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      accept: async () => {
        if(request.Action == 'N'){
          createModel(request);
        }else{
          updateModel(request);
        }
      }
    });
  };
  
  const createModel = (request) => {
    isUnitMeasureDialogOpen.value = false;
    isLoadingScreen.value = true;
    unitMeasureService.create(request).then(response => {
      if(response.Status == 201){
        toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
      }else{
        toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
      }
      submitted.value = false;
      isLoadingScreen.value = false;
      loadGridList();
    });
  }
  
  const updateModel = (request) => {
    isUnitMeasureDialogOpen.value = false;
    isLoadingScreen.value = true;
    unitMeasureService.update(request).then(response => {
      if(response.Status == 200){
        toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
      }else{
        toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
      }
      submitted.value = false;
      isLoadingScreen.value = false;
      loadGridList();
    });
  }
  
  
  const onEdit = (model) => {
    unitMeasureModel.value =  {...model };
    isUnitMeasureDialogOpen.value = true;
  };
  

  const deleteIndividual = ({ IdUnitMeasure }) => {
    confirm.require({
      message: '¿Está seguro de eliminar esta unidad de medida?',
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      acceptClass: 'p-button-danger',
      accept: async () => {
        isLoadingScreen.value = true;
        const dUser = JSON.parse(store.state.datauser);
        const idUserAction = dUser.User.IdUser;
        unitMeasureService.delete(IdUnitMeasure, idUserAction).then(response => {
          if(response.Status == 200){
            toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
          }else{
            toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
          }
          isLoadingScreen.value = false;
          loadGridList();
        });
      }
    });
  };
  
  
  
  const exportCSV = () => {
    dt.value.exportCSV();
  };
  
  
  const deleteSelected = () => {
    confirm.require({
          message: '¿Está seguro de eliminar todas las unidades de medida seleccionadas?',
          header: 'Confirmación',
          icon: 'pi pi-info-circle',
          acceptClass: 'p-button-danger',
          accept: async () => {
            isLoadingScreen.value = true;
            const dUser = JSON.parse(store.state.datauser);
            const idUserAction = dUser.User.IdUser;
            for (var i = 0; i < selectedItems.value.length; i++) {
              const { IdUnitMeasure } = selectedItems.value[i];
              await unitMeasureService.delete(IdUnitMeasure, idUserAction);
            }
            isLoadingScreen.value = false;
            toast.add({ severity: 'success', summary: 'Éxito', detail: "Registros eliminados de manera exitosa.", life: 3000 });
            loadGridList();
          }
      });
  };
  
  const initFilters = () => {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
  };
  
  </script>
    
  <style scoped lang="scss">
  @import '@/assets/demo/styles/badges.scss';
  .progress-spinner-custom {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .progress-spinner-custom:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.53);
  }
  </style>
  