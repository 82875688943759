<template>
  <!-- <div class="card flex justify-content-center"> -->
  <Dropdown
    v-model="selectedOption"
    :options="options"
    filter
    optionLabel="CodeName"
    placeholder="Seleccione un producto"
    @change="$emit('valueOption', selectedOption)"
    class="w-full"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex align-items-center">
        <!-- <img :alt="slotProps.value.label" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`mr-2 flag flag-${slotProps.value.code.toLowerCase()}`" style="width: 18px" /> -->
        <div>{{ slotProps.value.CodeName }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center">
        <!-- <img :alt="slotProps.option.label" src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`mr-2 flag flag-${slotProps.option.code.toLowerCase()}`" style="width: 18px" /> -->
        <div>{{ slotProps.option.CodeName }}</div>
      </div>
    </template>
  </Dropdown>
  <!-- </div> -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import ProductService from "@/service/ProductService";
import { useStore } from "vuex";

const store = useStore();

const productService = new ProductService();

const options = ref(null);
//var optionsAux = ref(null);
const selectedOption = ref();

const props = defineProps(["code", "isComboSelected"]);

onMounted(() => {

  let dUser = JSON.parse(store.state.datauser)

  productService.getProductsByBranchOffice(dUser.User.Branch).then((data) => {

    data.Data.forEach(function (element) {
      element.CodeName =
        element.CodeCoptos + " - " + element.Stock + " " + element.UnitMeassure + " - " + element.Name;
        // element.CodeCoptos + " - Cant: " + element.Stock + " - " + element.UnitMeassure + " - " + element.Name;
        //element.CodeCoptos + " - Cant: " + element.Stock + " - UM: " + element.UnitMeassure + " - " + element.Name;
    });

    options.value = data.Data;
  });
});
</script>