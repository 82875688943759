import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/styles.scss';

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import ToggleButton from 'primevue/togglebutton';
import Checkbox from 'primevue/checkbox';
import StyleClass from 'primevue/styleclass';
import Dropdown from 'primevue/dropdown';
import Chart from 'primevue/chart';
import InputNumber from 'primevue/inputnumber';
import Card from 'primevue/card';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Rating from 'primevue/rating';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import ProgressSpinner from 'primevue/progressspinner';
import AutoComplete from 'primevue/autocomplete';
import Menu from 'primevue/menu';
import Listbox from 'primevue/listbox'
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';
import Message from 'primevue/message';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';

createApp(App)
    .use(PrimeVue, { ripple: true })
    .use(ToastService)
    .use(store)
    .use(router)
    .use(DialogService)
    .use(ConfirmationService)
    .directive('styleclass', StyleClass)
    .directive('tooltip', Tooltip)
    .component('InputText', InputText)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('Password', Password)
    .component('InputMask', InputMask)
    .component('ToggleButton', ToggleButton)
    .component('Checkbox', Checkbox)
    .component('Dropdown', Dropdown)
    .component('Chart', Chart)
    .component('InputNumber', InputNumber)
    .component('Card', Card)
    .component('Toast', Toast)
    .component('Toolbar', Toolbar)
    .component('FileUpload', FileUpload)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('ColumnGroup', ColumnGroup)
    .component('Rating', Rating)
    .component('ConfirmDialog', ConfirmDialog)
    .component('Dialog', Dialog)
    .component('DynamicDialog', DynamicDialog)
    .component('Textarea', Textarea)
    .component('RadioButton', RadioButton)
    .component('ProgressSpinner', ProgressSpinner)
    .component('AutoComplete', AutoComplete)
    .component('Menu', Menu)
    .component('Listbox', Listbox)
    .component('Calendar', Calendar)
    .component('Tag', Tag)
    .component('Message', Message)
    .component('Tree', Tree)
    .mount('#app')