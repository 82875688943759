<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <div v-if="isLoadingScreen">
          <div class="progress-spinner-custom">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        </div>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Inactivar" icon="pi pi-power-off" class="p-button-danger" @click="deleteSelected"
                :disabled="!selectedItems || !selectedItems.length" />
            </div>
          </template>

          <template v-slot:end> </template>
        </Toolbar>

        <DataTable ref="dt" :value="branchOfficeList" v-model:selection="selectedItems" dataKey="IdBranchOffice"
          :paginator="true" :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50, 100]"
          currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} sucursales"
          responsiveLayout="scroll" :loading="isLoadingTable" class="p-datatable-sm">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Sucursales</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="Code" header="Codigo" :sortable="true" headerStyle="min-width:12rem;">
            <template #body="slotProps">
              <span class="p-column-title">Codigo</span>
              {{ slotProps.data.Code }}
            </template>
          </Column>
          <Column field="Address" header="Direccion" :sortable="true" headerStyle="min-width:12rem;">
            <template #body="slotProps">
              <span class="p-column-title">Direccion</span>
              {{ slotProps.data.Address }}
            </template>
          </Column>
          <Column field="Phone" header="Telefono" :sortable="true" headerStyle="min-width:12rem;">
            <template #body="slotProps">
              <span class="p-column-title">Telefono</span>
              {{ slotProps.data.Phone }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="onEdit(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="deleteIndividual(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="isbranchOfficeDialogOpen" :style="{ width: '480px' }" header="Detalle del Usuario"
          :modal="true" class="p-fluid">
          <div class="field">
            <label for="firstname">Codigo</label>
            <InputText id="firstname" v-model.trim="branchOfficeModel.Code" :maxlength="5" required="true" autofocus
              autocomplete="off" :class="{ 'p-invalid': submitted && !branchOfficeModel.Code }" />
            <small class="p-invalid" v-if="submitted && !branchOfficeModel.Code">El código es requerido.</small>
          </div>
          <div class="field">
            <label for="firstname">Direccion</label>
            <InputText id="firstname" v-model.trim="branchOfficeModel.Address" :maxlength="300" required="true" autofocus
              autocomplete="off" :class="{ 'p-invalid': submitted && !branchOfficeModel.Address }" />
            <small class="p-invalid" v-if="submitted && !branchOfficeModel.Address">La direccion es requerida.</small>
          </div>
          <div class="field">
            <label for="firstname">Telefono</label>
            <InputText id="firstname" v-model.trim="branchOfficeModel.Phone" :maxlength="20" required="true" autofocus
              autocomplete="off" :class="{ 'p-invalid': submitted }" />
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="onSave" />
          </template>
        </Dialog>

        <Dialog v-model:visible="isPermissionDialogOpen" :style="{ width: '480px' }" header="Administrar Permisos"
          :modal="true" class="p-fluid">
          <div class="flex justify-content-center">
            <Tree v-model:selectionKeys="selectedKey" :value="nodes" selectionMode="checkbox" class="w-full md:w-30rem">
            </Tree>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hidePermissionDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="onSavePermission" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script setup>
import { FilterMatchMode } from "primevue/api";
import { ref, onMounted, onBeforeMount } from "vue";
import BranchOfficeService from "@/service/BranchOfficeService";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { useStore } from "vuex";

const store = useStore();
const confirm = useConfirm();
const toast = useToast();

const branchOfficeList = ref(null);
const isbranchOfficeDialogOpen = ref(false);
const isPermissionDialogOpen = ref(false);
const isLoadingTable = ref(false);
const branchOfficeModel = ref({});

const selectedItems = ref(null);
const dt = ref(null);
const filters = ref({});
const submitted = ref(false);
const isLoadingScreen = ref(false);

const branchOfficeService = new BranchOfficeService();
const nodes = ref(null);

onBeforeMount(() => {
  initFilters();
});

onMounted(() => {
  loadData();
});

const loadGridList = () => {
  isLoadingTable.value = true;
  branchOfficeService.getBranchsOffice().then((response) => {
    branchOfficeList.value = response.Data;
    isLoadingTable.value = false;
  });
};

const loadData = () => {
  loadGridList();
};

const openNew = () => {
  branchOfficeModel.value = {};
  submitted.value = false;
  isbranchOfficeDialogOpen.value = true;
};

const hideDialog = () => {
  isPermissionDialogOpen.value = false;
  submitted.value = false;
  branchOfficeModel.value = {};
};

const exportCSV = () => {
  dt.value.exportCSV();
};

const deleteSelected = () => {
  confirm.require({
    message: "¿Está seguro de eliminar todas las sucursales seleccionadas?",
    header: "Confirmación",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    accept: async () => {
      isLoadingScreen.value = true;
      for (var i = 0; i < selectedItems.value.length; i++) {
        const { IdBranchOffice } = selectedItems.value[i];
        await branchOfficeService.deleteBranchOffice(IdBranchOffice);
      }
      isLoadingScreen.value = false;
      toast.add({
        severity: "success",
        summary: "Éxito",
        detail: "Registros eliminados de manera exitosa.",
        life: 3000,
      });
      loadGridList();
    },
  });
};

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
};

const hidePermissionDialog = () => {
  isPermissionDialogOpen.value = false;
};

const onSavePermission = () => {
  alert("saved");
};

const onSave = () => {
  submitted.value = true;
  const { ...model } = { ...branchOfficeModel.value };
  if (!model?.Code) {
    toast.add({
      severity: "warn",
      summary: "Advertencia",
      detail: "Por favor ingrese el nombre del codigo de la sucursal.",
      life: 3000,
    });
    return;
  }
  if (!model?.Address) {
    toast.add({
      severity: "warn",
      summary: "Advertencia",
      detail: "Por favor ingrese la dirección de la sucursal.",
      life: 3000,
    });
    return;
  }

  if (!model.Phone) model.Phone = "";
  const request = {
    ...model,
    Action: (model?.IdBranchOffice ?? 0) > 0 ? "U" : "N",
  };

  confirm.require({
    message: "¿Está seguro de guardar los cambios?",
    header: "Confirmación",
    icon: "pi pi-info-circle",
    accept: async () => {
      if (request.Action == "N") {
        createModel(request);
      } else {
        updateModel(request);
      }
    },
  });
};

const createModel = (request) => {
  isbranchOfficeDialogOpen.value = false;
  isLoadingScreen.value = true;
  branchOfficeService.addBranchOffice(request).then((response) => {
    if (response.Status == 201) {
      toast.add({
        severity: "success",
        summary: "Éxito",
        detail: response.Message,
        life: 3000,
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: response.Message,
        life: 3000,
      });
    }
    submitted.value = false;
    isLoadingScreen.value = false;
    loadGridList();
  });
};

const updateModel = (request) => {
  isbranchOfficeDialogOpen.value = false;
  isLoadingScreen.value = true;
  branchOfficeService.updateBranchOffice(request).then(response => {
    if (response.Status == 200) {
      toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
    }
    submitted.value = false;
    isLoadingScreen.value = false;
    loadGridList();
  });
}

const onEdit = (model) => {
  branchOfficeModel.value = { ...model };
  isbranchOfficeDialogOpen.value = true;
};

const deleteIndividual = ({ IdBranchOffice }) => {
  confirm.require({
    message: '¿Está seguro de eliminar esta sucursal?',
    header: 'Confirmación',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      isLoadingScreen.value = true;
      branchOfficeService.deleteBranchOffice(IdBranchOffice).then(response => {
        if (response.Status == 200) {
          toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
        } else {
          toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
        }
        isLoadingScreen.value = false;
        loadGridList();
      });
    }
  });
};
</script>

<style scoped lang="scss"></style>
