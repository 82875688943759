export default class BranchOfficeService {
  getBranchsOffice() {
    return fetch(process.env.VUE_APP_API_IOT + "/v1/branch-office").then(
      (res) => res.json()
    );
    //.then((d) => d.Data);
  }

  async getBranchsOfficeAssignation() {
    const response = await fetch(
      process.env.VUE_APP_API_IOT + "/v1/branch-office",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.json();
  }

  async addBranchOffice(request) {
    const response = await fetch(
      process.env.VUE_APP_API_IOT + "/v1/branch-office",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );

    return response.json();
  }

  async updateBranchOffice(request) {
    const response = await fetch(
      process.env.VUE_APP_API_IOT + "/v1/branch-office",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );
    return response.json();
  }

  async deleteBranchOffice(id) {
    const response = await fetch(
      process.env.VUE_APP_API_IOT + "/v1/branch-office/" + id,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  }
}
