<template>
  <div>
    <Dropdown
      v-model="selectedOption"
      :disabled="isComboSelected"
      @change="$emit('valueOption', selectedOption.IdBranchOffice)"
      :options="options"
      optionLabel="Address"
      placeholder="Seleccione una sucursal"
      class="w-full"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import BranchOfficeService from "@/service/BranchOfficeService";
import { useStore } from "vuex";

const store = useStore();

const branchOfficeService = new BranchOfficeService();

const options = ref(null);
const selectedOption = ref();

const props = defineProps(["code", "isComboSelected"]);

onMounted(() => {
  branchOfficeService.getBranchsOffice().then((data) => {
    options.value = data.Data;
  });
});
</script>