<template>
  <div
    class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <img src="/layout/images/background_workers.jpeg" alt="Hero Image" style="opacity: 0.1; width: 100%; position: absolute;" />
    <Toast />
    <div v-if="isLoadinga">
      <div class="progress-spinner-custom">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
    </div>

    <div class="flex flex-column align-items-center justify-content-center" style="position: relative;">
      <!-- <img src="layout/images/logo-dark.svg" alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0" /> -->
      <!-- <img src="layout/images/logo-coptos3-bg.png" alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0"/>  -->
      <div
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            #22C55E 10%,
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="w-full surface-card py-8 px-5 sm:px-8"
          style="border-radius: 53px;"
        >
          <div class="text-center mb-5">
            <!-- <img src="/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3" /> -->
            <img src="layout/images/logo-coptos3-bg.png" alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0"/> 
            <div class="text-900 text-3xl font-medium mb-3">
              Bienvenido, Coptos v1.0
            </div>
            <span class="text-600 font-medium"
              >Inicie sesión para continuar</span
            >
          </div>

          <div>
            <label class="block text-900 text-xl font-medium mb-2"
              >Usuario</label
            >
            <InputText
              id="email1"
              type="text"
              placeholder="Usuario"
              class="w-full md:w-30rem mb-5"
              style="padding: 1rem"
              v-model="email"
            />
            <label class="block text-900 font-medium text-xl mb-2"
              >Contraseña</label
            >
            <Password
              id="password1"
              v-model="password"
              placeholder="Contraseña"
              class="w-full mb-3"
              inputClass="w-full"
              inputStyle="padding:1rem"
            ></Password>

            <div
              class="flex align-items-center justify-content-between mb-5 gap-5"
            >
              <!-- <div class="flex align-items-center">
                                <Checkbox v-model="checked" id="rememberme1" binary class="mr-2"></Checkbox>
                                <label>Remember me</label>
                            </div> -->
              <!-- <a class="font-medium no-underline ml-2 text-right cursor-pointer"
                                style="color: var(--primary-color)">Forgot password?</a> -->
            </div>
            <Button
              label="Ingresar"
              class="w-full p-3 text-xl p-button-success"
              @click="login"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <AppConfig simple /> -->
</template>
  
<script setup>
import { ref } from "vue";
import UserService from "@/service/UserService";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
const router = useRouter();
const toast = useToast();
const store = useStore()

const email = ref("");
const password = ref(null);
const checked = ref(false);
const isLoadinga = ref(false);
const response = ref(null);

const userService = new UserService();

const login = async () => {
  try {
    // console.log(process.env.BASE_URL);
    isLoadinga.value = true;
    // console.log(isLoadinga.value);
    response.value = await userService.login(email.value, password.value);

    if (response.value.Status !== 200) {
      isLoadinga.value = false;
      if (response.value.Message !== "Success" && response.value.Message !== undefined) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: response.value.Message,
          life: 3000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error en inicio de sesión",
          life: 3000,
        });
      }

      return;
    }
    // console.log("en login...")
    // console.log(JSON.stringify(response.value.Data))
    store.commit('login',JSON.stringify(response.value.Data))
    isLoadinga.value = false;
    router.push({ path: "/system" });
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 3000,
    });
  } finally {
    isLoadinga.value = false;
    // setTimeout(() => this.$store.commit("setShowAlert", false), 3000);
  }
};
</script>
<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}

.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}
</style>