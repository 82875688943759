<script setup>
import { ref, onMounted } from "vue";
import AppMenuItem from "./AppMenuItem.vue";
import { useStore } from "vuex";

const store = useStore();

const model = ref([]);

const Fn_Childs = (idMenuParent, data) => {
  let item = data
    .filter((d) => d.IdMenuParent == idMenuParent)
    .map((d2) => {
      return {
        label: d2.Name,
        icon: d2.Icon ? d2.Icon : "",
        to: d2.To ? d2.To : "#",
        items: Fn_Childs(d2.IdMenu, data),
      };
    });
  return item;
};

onMounted(() => {
  let dUser = JSON.parse(store.state.datauser);

  //dUser.MenuOptions = dUser.MenuOptions.map()

  let menuArray = dUser.MenuOptions.filter((d) => d.Level == 0).map((d) => {
    return {
      label: d.Name,
      items: Fn_Childs(d.IdMenu, dUser.MenuOptions),
    };
  });


// menuArray[2]
// {label: 'Administración', items: Array(2)}

// menuArray[2].items[1]
// {label: 'Reportes', icon: 'pi pi-fw pi-bookmark', to: '#', items: Array(3)}

// //menuArray[2].items[1].items[0]
// {label: 'Stock', icon: 'pi pi-fw pi-inbox', to: '/system/report-stock', items: Array(0)}

// menuArray[2].items[1].items[0].items
// []

for (let i = 0; i < menuArray.length; i++) {
  if(menuArray[i].items.length > 0){
    for(let j = 0; j < menuArray[i].items.length; j++){
      if(menuArray[i].items[j].items.length > 0){
        for(let k = 0; k < menuArray[i].items[j].items.length; k++){
          if(menuArray[i].items[j].items[k].items.length){
            //empty
          }else{
            delete menuArray[i].items[j].items[k].items
          }
        }
      }else{
        delete menuArray[i].items[j].items
      }
    }
  }else{
    delete menuArray[i].items;
  }
}

  model.value = menuArray;

  // model.value = [
  //   {
  //     label: "Inicio",
  //     items: [
  //       { label: "Panel General", icon: "pi pi-fw pi-home", to: "/system" },
  //     ],
  //   },
  //   {
  //     label: "Gestión",
  //     items: [
  //       {
  //         label: "Nota de Ingreso",
  //         icon: "pi pi-fw pi-arrow-down",
  //         to: "/system/note-entry",
  //       },
  //       {
  //         label: "Nota de Salida",
  //         icon: "pi pi-fw pi-arrow-up",
  //         to: "/system/note-exit",
  //       },
  //     ],
  //   },
  //   {
  //     label: "Administración",
  //     items: [
  //       {
  //         label: "Mantenedores",
  //         icon: "pi pi-fw pi-bookmark",
  //         items: [
  //           {
  //             label: "Producto",
  //             icon: "pi pi-fw pi-box",
  //             to: "/system/deposit",
  //           },
  //         ],
  //       },
  //       {
  //         label: "Reportes",
  //         icon: "pi pi-fw pi-bookmark",
  //         items: [
  //           {
  //             label: "Stock",
  //             icon: "pi pi-fw pi-inbox",
  //             to: "/system/report-stock",
  //           },
  //           {
  //             label: "Ingresos",
  //             icon: "pi pi-fw pi-bookmark",
  //           },
  //           {
  //             label: "Salidas",
  //             icon: "pi pi-fw pi-bookmark",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];
});
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
    <!-- <li>
            <a href="https://www.primefaces.org/primeblocks-vue/#/" target="_blank">
                <img src="/layout/images/banner-primeblocks.png" alt="Prime Blocks" class="w-full mt-3" />
            </a>
        </li> -->
  </ul>
</template>

<style lang="scss" scoped></style>
