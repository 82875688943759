import { createStore } from 'vuex'

export default createStore({
  state: {
    datauser: null
  },
  getters: {
    isAuthenticated(state) {
      //return localStorage.getItem('datauser');
      return state.datauser;    
    },
  },
  mutations: {
    login(state, data) {
      state.datauser = data;
      localStorage.setItem('datauser', data);
    },
    logout(state) {
      state.datauser = null;
      localStorage.removeItem('datauser');
    },
    initialiseStore(state) {
      if (localStorage.getItem('datauser')) {
        state.datauser = localStorage.getItem('datauser');
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
