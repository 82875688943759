<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Nota de Ingreso Gestionadas</span
            >
            <div class="text-900 font-medium text-xl">{{countNoteEntry}}</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-green-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-arrow-circle-down text-green-500 text-xl"></i>
          </div>
        </div>
        <!-- <span class="text-green-500 font-medium">24 new </span>
        <span class="text-500">since last visit</span> -->
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Nota de Salida Gestionadas</span
            >
            <div class="text-900 font-medium text-xl">{{countNoteExit}}</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-red-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-arrow-circle-up text-red-500 text-xl"></i>
          </div>
        </div>
        <!-- <span class="text-green-500 font-medium">%52+ </span>
        <span class="text-500">since last week</span> -->
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Stock Total de Productos</span
            >
            <div class="text-900 font-medium text-xl">
              {{ countStockProducts }}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-purple-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-inbox text-purple-500 text-xl"></i>
          </div>
        </div>
        <!-- <span class="text-green-500 font-medium">520 </span>
        <span class="text-500">newly registered</span> -->
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Cantidad Total de Productos</span
            >
            <div class="text-900 font-medium text-xl">{{ countProducts }}</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-cyan-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-box text-cyan-500 text-xl"></i>
          </div>
        </div>
        <!-- <span class="text-green-500 font-medium">85 </span>
        <span class="text-500">responded</span> -->
      </div>
    </div>

    <div class="col-12 xl:col-6">
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Productos con Stock Bajo</h5>
          <!-- <div>
                        <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
                        <Menu ref="menu2" :popup="true" :model="items"></Menu>
                    </div> -->
        </div>
        <ul class="list-none p-0 m-0">
          <li v-for="item in smallProductsStockMin"  
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0"
                >{{item.CodeCoptos + ' - ' + item.Name}}</span
              >
              <div class="mt-1 text-600">{{item.UnitMeassure + ' - ' + item.Category}}</div>

              <!-- <div class="mt-1 text-600">{{item.Category}}</div> -->
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-pink-500 h-full" :style="'width: '+ item.Stock +'%'"></div>
              </div>
              <!-- <span class="text-pink-500 ml-3 font-medium">%12</span> -->
              <span class="text-pink-500 ml-3 font-medium">{{item.Stock}}</span>
            </div>
          </li>
          <!-- <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0"
                >Mat Black Case</span
              >
              <div class="mt-1 text-600">Accessories</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-pink-500 h-full" style="width: 28%"></div>
              </div>
              <span class="text-pink-500 ml-3 font-medium">%28</span>
            </div>
          </li>
          <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0"
                >Robots T-Shirt</span
              >
              <div class="mt-1 text-600">Clothing</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-pink-500 h-full" style="width: 35%"></div>
              </div>
              <span class="text-pink-500 ml-3 font-medium">%35</span>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="col-12 xl:col-6">
      <div class="card">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5>Productos con Stock Alto</h5>
          <!-- <div>
            <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded"
              @click="$refs.menu2.toggle($event)"></Button>
            <Menu ref="menu2" :popup="true" :model="items"></Menu>
          </div> -->
        </div>
        <ul class="list-none p-0 m-0">
          <li v-for="item in smallProductsStockMax"
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0"
                >{{item.CodeCoptos + ' - ' + item.Name}}</span
              >
              <!-- <div class="mt-1 text-600">Clothing</div> -->
              <div class="mt-1 text-600">{{item.UnitMeassure + ' - ' + item.Category}}</div>
              <!-- <div class="mt-1 text-600">{{item.Category}}</div> -->
            </div>
            <div class="mt-2 md:mt-0 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-green-500 h-full" :style="'width: '+ item.Stock +'%'"></div>
              </div>
              <span class="text-green-500 ml-3 font-medium">{{item.Stock}}</span>
            </div>
          </li>
          <!-- <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0"
                >Portal Sticker</span
              >
              <div class="mt-1 text-600">Accessories</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-green-500 h-full" style="width: 85%"></div>
              </div>
              <span class="text-green-500 ml-3 font-medium">%85</span>
            </div>
          </li>
          <li
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
          >
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0"
                >Supernova Sticker</span
              >
              <div class="mt-1 text-600">Accessories</div>
            </div>
            <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex align-items-center">
              <div
                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                style="height: 8px"
              >
                <div class="bg-green-500 h-full" style="width: 79%"></div>
              </div>
              <span class="text-green-500 ml-3 font-medium">%79</span>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import moment from "moment";
import { ref, onMounted } from "vue";
import ProductService from "@/service/ProductService";
import ReportService from "@/service/ReportService";
import { useStore } from "vuex";

const store = useStore();

const productService = new ProductService();
const reportService = new ReportService();

const smallProductsStockMin = ref([]);
const smallProductsStockMax = ref([]);

const countProducts = ref(0);
const countStockProducts = ref(0);
const countNoteEntry = ref(0);
const countNoteExit = ref(0);

onMounted(() => {
  let dUser = JSON.parse(store.state.datauser);

  let dateAux = moment();
  let dateFormat = dateAux.format("YYYY-MM-DD");

  productService.getProductsByBranchOffice(dUser.User.Branch).then((data) => {
    countStockProducts.value = data.Data.reduce(
      (prev, curr) => prev + curr.Stock,
      0
    );

    countProducts.value = data.Count;

    smallProductsStockMin.value = data.Data.sort((a,b) =>  a.Stock - b.Stock )
    smallProductsStockMin.value = smallProductsStockMin.value.slice(0,5)
    console.log(smallProductsStockMin.value)

    smallProductsStockMax.value = data.Data.sort((a,b) =>  b.Stock - a.Stock )
    smallProductsStockMax.value = smallProductsStockMax.value.slice(0,5)
    //console.log(smallProductsStockMax.value)
  });

  const allBranchOffice = -1;
  reportService
    .getReportNoteEntry(allBranchOffice, "2000-01-01", dateFormat)
    .then((data) => {
      countNoteEntry.value = data.Count;
    });

  reportService
    .getReportNoteExit(allBranchOffice, "2000-01-01", dateFormat)
    .then((data) => {
      countNoteExit.value = data.Count;
    });
});
</script>
  