<script setup>
import moment from "moment";
import { ref, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";

// import DropdownCurrency from "@/components/Dropdowns/DropdownCurrency";
import DropdownBranchOffice from "@/components/Dropdowns/DropdownBranchOffice.vue";
import DropdownReasonExit from "@/components/Dropdowns/DropdownReasonExit.vue";
import DropdownDestination from "@/components/Dropdowns/DropdownDestination.vue";
import DropdownProduct from "@/components/Dropdowns/DropdownProduct.vue";
import NoteExitService from "@/service/NoteExitService";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const toast = useToast();

const noteExitService = new NoteExitService();

// const symbolCurrency = ref("");
// const selectedCurrency = ref(0);
const selectedReasonExit = ref(0);
const selectedBranchOffice = ref(0);
const selectedDestination = ref(0);
const countTotal = ref(0);
const received = ref("");
const observations = ref("");
const calendarValue = ref("");
const productName = ref(null);
const valueQuantityProduct = ref(0.01);
// const valuePriceProduct = ref(1);
//const myuuid = ref("001-" + uuidv4().substr(0, 10));
const myuuid = ref("-");
const productDialog = ref(false);
const deleteProductDialog = ref(false);
const confirmNoteEntryDialog = ref(false);
const confirmNoteExitSave = ref(false);
const product = ref({});
const selectedProductDetailTemp = ref({});
const isLoadinga = ref(false);

const productsTmp = ref([]);

onMounted(() => {
  noteExitService
    .getCodeGeneric()
    .then((data) => (myuuid.value = data.Data.CodeGeneric));
});

// const getValueCurrency = (value) => {
//   console.log(value);
//   symbolCurrency.value = value == 2 ? "S/." : "$";
//   selectedCurrency.value = value;
// };

const getValueReasonExit = (value) => {
  console.log(value);
  selectedReasonExit.value = value;
};

const getValueDestination = (value) => {
  console.log(value);
  selectedDestination.value = value;
};

const getValueBranchOffice = (value) => {
  console.log(value);
  selectedBranchOffice.value = value;
};

const openNew = () => {
  productDialog.value = true;

  // if (selectedCurrency.value != 0) {
  //   productDialog.value = true;
  // } else {
  //   toast.add({
  //     severity: "error",
  //     summary: "Mensaje Error",
  //     detail: "Debe seleccionar una moneda.",
  //     life: 3000,
  //   });
  // }
};

const hideDialog = () => {
  productDialog.value = false;
  // submitted.value = false;
};

const getValueProductDetail = (value) => {
  console.log(value);
  selectedProductDetailTemp.value = value;
  // product.value.id = value
};

const sumTotalEntry = () => {
  countTotal.value = productsTmp.value.reduce(
    (sum, { quantity }) => sum + quantity,
    0
  );
};

const saveProductTemp = () => {
  if (selectedProductDetailTemp.value.IdProduct !== undefined) {
    let idsAux = [];
    idsAux = productsTmp.value.map((row) => {
      return row.id;
    });

    if (idsAux.indexOf(selectedProductDetailTemp.value.IdProduct) == -1) {
      let ptemp = {
        id: selectedProductDetailTemp.value.IdProduct,
        code: selectedProductDetailTemp.value.CodeCoptos,
        name: selectedProductDetailTemp.value.Name,
        unitMeassure: selectedProductDetailTemp.value.UnitMeassure,
        quantity: valueQuantityProduct.value,
        // price: valuePriceProduct.value,
      };

      productsTmp.value.push(ptemp);

      selectedProductDetailTemp.value = {};
      valueQuantityProduct.value = 0.01;
      // valuePriceProduct.value = 1;

      sumTotalEntry();

      productDialog.value = false;
    } else {
      toast.add({
        severity: "error",
        summary: "Mensaje Error",
        detail: "El producto ya a sido añadido.",
        life: 3000,
      });
    }
  } else {
    toast.add({
      severity: "error",
      summary: "Mensaje Error",
      detail: "Debe elegir un producto.",
      life: 3000,
    });
  }
};

const confirmDeleteProduct = (editProduct) => {
  product.value = editProduct;
  deleteProductDialog.value = true;
};

const deleteProduct = () => {
  productsTmp.value = productsTmp.value.filter(
    (val) => val.id !== product.value.id
  );
  deleteProductDialog.value = false;
  product.value = {};
  sumTotalEntry();
  toast.add({
    severity: "success",
    summary: "Éxito",
    detail: "Producto excluido.",
    life: 3000,
  });
};

const clickConfirmNoteExit = () => {
  if (validatesMsg() == "") {
    confirmNoteExitSave.value = true;
  } else {
    toast.add({
      severity: "error",
      summary: "Mensaje Error",
      detail: validatesMsg(),
      life: 3000,
    });
  }
};

const saveNoteExit = async () => {
  // toast.add({
  //   severity: "success",
  //   summary: "Mensaje",
  //   detail: store.state.datauser,
  //   life: 3000,
  // });

  if (validatesMsg() == "") {
    confirmNoteExitSave.value = false
    let dUser = JSON.parse(store.state.datauser);

    let response;
    let hourAux = moment().format("HH:mm:ss");
    let dateAux = moment(calendarValue.value);
    let dateComplete = dateAux.format("YYYY-MM-DD") + " " + hourAux;

    const data = {
      IdReasonExit: selectedReasonExit.value,
      IdBranchOffice: selectedBranchOffice.value,
      IdDestination: selectedDestination.value,
      Code: myuuid.value,
      DateExit: dateComplete,
      Received: received.value,
      Observation: observations.value,
      CreationUser: dUser.User.IdUser,
      Detail: [],
    };

    const dataDetail = productsTmp.value.map((row) => {
      return {
        IdProduct: row.id,
        Quantity: row.quantity,
      };
    });

    data.Detail = dataDetail; //console.log(data);
    isLoadinga.value = true;
    response = await noteExitService.addNoteExit(data); //console.log(response);
    if (response.Status == 201) {
      isLoadinga.value = false;
      confirmNoteEntryDialog.value = true;
    } else {
      isLoadinga.value = false;
      toast.add({
        severity: "error",
        summary: "Mensaje Error",
        detail: response.Message,
        life: 6000,
      });
    }
  } else {
    toast.add({
      severity: "error",
      summary: "Mensaje Error",
      detail: validatesMsg(),
      life: 3000,
    });
  }
};

const validatesMsg = () => {
  let res = "";
  if (productsTmp.value.length == 0) {
    res = "Debe añadir al menos un producto.";
  }
  // if (selectedCurrency.value == 0) {
  //   res = "Debe seleccionar una moneda.";
  // }
  if (selectedDestination.value == 0) {
    res = "Debe seleccionar un destino.";
  }
  if (received.value == "") {
    res = "Debe ingresar el responsable a recibir.";
  }
  if (calendarValue.value == "") {
    res = "Debe ingresar una fecha valida.";
  }
  if (selectedReasonExit.value == 0) {
    res = "Debe seleccionar motivo de salida.";
  }
  if (selectedBranchOffice.value == 0) {
    res = "Debe seleccionar sucursal.";
  }

  return res;
};

const resetInputs = () => {
  router.push({ path: "/system" });

  /*productsTmp.value = [];
  selectedCurrency.value = 0;
  numberGuia.value = "";
  calendarValue.value = "";
  observations.value = "";
  selectedReasonExit.value = 0;
  selectedBranchOffice.value = 0*/
};
</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />

        <div v-if="isLoadinga">
          <div class="progress-spinner-custom">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        </div>

        <div class="flex mb-3">
          <div
            class="flex align-items-center justify-content-center bg-red-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-file-export text-red-500 text-xl"></i>
          </div>

          <h5 style="line-height: 0%; margin-left: 5px;">Nota de Salida</h5>
        </div>

        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <!-- <label for="zip" class="font-bold block mb-2">&nbsp;&nbsp;</label> -->
            <label for="zip" class="font-bold block mb-2">Código</label>
            <Button
              class="p-button-outlined p-button-info mr-2 mb-2 justify-content-center"
              >{{ myuuid }}</Button
            >
          </div>
          <div class="field col-12 md:col-3">
            <label for="zip" class="font-bold block mb-2">Sucursal</label>
            <DropdownBranchOffice
              @valueOption="getValueBranchOffice"
              v-bind:isComboSelected="false"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="zip" class="font-bold block mb-2">Motivo Salida</label>
            <DropdownReasonExit
              @valueOption="getValueReasonExit"
              v-bind:isComboSelected="false"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="zip" class="font-bold block mb-2">Fecha Salida</label>
            <Calendar
              :showIcon="true"
              :showButtonBar="true"
              v-model="calendarValue"
            ></Calendar>
          </div>

          <div class="field col-12 md:col-3">
            <label for="zip" class="font-bold block mb-2"
              >Responsable a Recibir</label
            >
            <InputText
              type="text"
              placeholder="Responsable"
              v-model="received"
            ></InputText>
          </div>
          <!-- <div class="field col-12 md:col-3">
            <label for="zip" class="font-bold block mb-2">Moneda</label>
            <DropdownCurrency
              @valueOption="getValueCurrency"
              v-bind:isComboSelected="false"
            />
          </div> -->
          <div class="field col-12 md:col-3">
            <label for="zip" class="font-bold block mb-2">Destino</label>
            <DropdownDestination
              @valueOption="getValueDestination"
              v-bind:isComboSelected="false"
            />
          </div>
          <div class="field col-12 md:col-6">
            <label for="address" class="font-bold block mb-2"
              >Observaciones</label
            >
            <Textarea id="address" rows="1" v-model="observations" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 xl:col-9">
      <div class="card">
        <!-- <h5>Detalle Productos</h5> -->
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <!-- <label for="zip" class="font-bold block mb-2"
              ><span>&nbsp;&nbsp;</span></label
            > -->
            <Button
              @click="openNew"
              label="Agregar Producto"
              icon="pi pi-plus"
              class="mr-2 p-button-success"
            />
          </div>
          <div class="field col-12 md:col-9">
            <label for="zip"></label>
            <!-- <DropdownProduct /> -->
          </div>
        </div>

        <DataTable
          ref="dt"
          :value="productsTmp"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="5"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} productos"
          responsiveLayout="scroll"
          :loading="isLoadingTable"
          class="p-datatable-sm"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0">Productos</h5>
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span> -->
            </div>
          </template>

          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
          <Column
            field="code"
            header="Código"
            :sortable="true"
            headerStyle="width:14%; min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              {{ slotProps.data.code }}
            </template>
          </Column>
          <Column
            field="name"
            header="Nombre"
            :sortable="true"
            headerStyle="width:14%; min-width:30rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.name }}
            </template>
          </Column>

          <Column
            field="unitMeassure"
            header="UM"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">UM</span>
              {{ slotProps.data.unitMeassure }}
            </template>
          </Column>
          <Column
            field="quantity"
            header="Cantidad"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Cantidad</span>
              {{ slotProps.data.quantity }}
            </template>
          </Column>
          <!-- <Column
            field="price"
            header="Precio"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Precio</span>
              {{ symbolCurrency + " " + slotProps.data.price }}
            </template>
          </Column> -->
          <!-- <Column
            field="state"
            header="Estado"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Estado</span>
              <span
                :class="
                  'product-badge-custom status-' +
                  (slotProps.data.state
                    ? slotProps.data.state.toLowerCase()
                    : '')
                "
                >{{ slotProps.data.state }}</span
              >
            </template>
          </Column> -->
          <Column headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <!-- <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              /> -->
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="col-12 xl:col-3">
      <div class="card">
        <h5>Totales</h5>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label for="zip" class="font-normal block mb-2"
              ><span>Productos salientes: <strong>{{ countTotal }}</strong> </span></label
            >
            <!-- <label for="zip" class="font-bold block mb-2"
              ><span>Cantidad Producto 2 - 15</span></label
            >
            <label for="zip" class="font-bold block mb-2"
              ><span>Cantidad Producto 3 - 55</span></label
            > -->
            <Button
              @click="clickConfirmNoteExit"
              label="Guardar"
              icon="pi pi-save"
              class="mr-2"
            />
          </div>
          <!-- <div class="field col-12 md:col-9">
            <label for="zip"></label>
            <DropdownProduct /> 
          </div> -->
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="productDialog"
      :style="{ width: '450px' }"
      header="Seleccionar Producto"
      :modal="true"
      class="p-fluid"
    >
      <!-- <img :src="'/demo/images/product/' + product.image" :alt="product.image" v-if="product.image" width="150"
            class="mt-0 mx-auto mb-5 block shadow-2" /> -->
      <div class="field">
        <label for="name" class="font-bold block mb-2">Producto</label>
        <DropdownProduct @valueOption="getValueProductDetail" />
        <!-- <InputText id="name" v-model.trim="product.name_deposit" required="true" autofocus
              :class="{ 'p-invalid': submitted && !product.name_deposit }" />
            <small class="p-invalid" v-if="submitted && !product.name_deposit">Name is required.</small> -->
      </div>
      <!-- <div class="field">
            <label for="description">Description</label>
            <Textarea id="description" v-model="product.description" required="true" rows="3" cols="20" />
          </div> -->
      <div class="formgrid grid">
        <div class="field col">
          <label for="zip" class="font-bold block mb-2">Cantidad</label>
          <InputNumber
            v-model="valueQuantityProduct"
            inputId="horizontal-buttons"
            showButtons
            buttonLayout="horizontal"
            :step="1"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            :min="0.01"
            mode="decimal"
            :minFractionDigits="2"
          />
        </div>
        <!-- <div class="field col">
          <label for="zip" class="font-bold block mb-2">Precio</label>
          <InputNumber
            v-if="selectedCurrency == 2"
            v-model="valuePriceProduct"
            :min="1"
            inputId="stacked-buttons"
            showButtons
            mode="currency"
            currency="PEN"
          />
          <InputNumber
            v-else
            v-model="valuePriceProduct"
            :min="1"
            inputId="stacked-buttons"
            showButtons
            mode="currency"
            currency="USD"
          />
        </div> -->
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Agregar"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveProductTemp"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteProductDialog"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="product"
          >Esta seguro que quiere excluir el producto <b>{{ product.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteProductDialog = false"
        />
        <Button
          label="Si"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteProduct"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="confirmNoteExitSave"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="product">Esta seguro de guardar los cambios?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="confirmNoteExitSave = false"
        />
        <Button
          label="Si"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveNoteExit"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="confirmNoteEntryDialog"
      :style="{ width: '450px' }"
      header="Éxito"
      :modal="true"
      :closable="false"
    >
      <div class="flex align-items-center justify-content-center">
        <!-- <span>Se creo la nota de entrada: <b>{{ myuuid }}</b></span> -->
        <Message severity="success" :closable="false"
          ><span
            >Se Registro la Nota de Salida: <b>{{ myuuid }}</b></span
          ></Message
        >
      </div>
      <template #footer>
        <Button
          label="Ok"
          icon="pi pi-check"
          class="p-button-text"
          @click="resetInputs"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}
</style>