<template>
  <div>
    <Dropdown
      v-model="selectedOption"
      :disabled="isComboSelected"
      @change="$emit('valueOption', selectedOption.IdDestination)"
      :options="options"
      optionLabel="Name"
      placeholder="Seleccione un destino"
      class="w-full"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import DestinationService from "@/service/DestinationService";
import { useStore } from "vuex";

const store = useStore();

const destinationService = new DestinationService();

const options = ref(null);
const selectedOption = ref();

const props = defineProps(['code', 'isComboSelected'])

onMounted(() => { 
    destinationService.getDestinations().then((data) => (options.value = data.Data));  
});
</script>