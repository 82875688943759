 <template>
  <div>
    <div class="card">
      <h5>Notas de Salida</h5>
      <Toast />
      <Toolbar class="mb-4">
        <template v-slot:start> </template>

        <template v-slot:end>
          <Button
            label="Exportar"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="zip" class="font-bold block mb-2">Fecha de Inicio</label>
          <Calendar
            :showIcon="true"
            :showButtonBar="true"
            v-model="calendarValueBegin"
          ></Calendar>
        </div>
        <div class="field col-12 md:col-4">
          <label for="zip" class="font-bold block mb-2">Fecha de Fin</label>
          <Calendar
            :showIcon="true"
            :showButtonBar="true"
            v-model="calendarValueEnd"
          ></Calendar>
        </div>
        <div class="field col-12 md:col-4">
          <label for="zip" class="font-bold block mb-2"
            ><span>&nbsp;&nbsp;</span></label
          >
          <Button
            label="Filtrar"
            icon="pi pi-search"
            class="p-button-success mr-2"
            @click="filterByDates"
          />
        </div>
      </div>
      <br />
      <DataTable
        ref="dt"
        :value="products"
        :paginator="true"
        :rows="10"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50, 100]"
        currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} notas de salida"
        v-model:expandedRows="expandedRows"
        dataKey="IdNoteExit"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :loading="isLoadingTable"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
          >
            <div>
              <Button
                icon="pi pi-plus"
                label="Expandir todo"
                @click="expandAll"
                class="mr-2 mb-2"
              />
              <Button
                icon="pi pi-minus"
                label="Contraer todo"
                @click="collapseAll"
                class="mb-2"
              />
            </div>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>

        <Column :expander="true" headerStyle="min-width: 3rem" />
        <Column field="Code" header="Codigo" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.Code }}
          </template>
        </Column>
        <Column field="ReasonExit" header="Motivo Salida" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.ReasonExit }}
          </template>
        </Column>
        <!-- <Column field="Sucursal" header="Sucursal" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.Branch }}
          </template>
        </Column> -->
        <Column field="DateExit" header="Fecha Salida" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.DateExit }}
          </template>
        </Column>
        <Column field="Received" header="Responsable" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.Received }}
          </template>
        </Column>
        <Column field="Observation" header="Observaciones" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.Observation }}
          </template>
        </Column>
        <Column field="CreationUser" header="Usuario" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.CreationUser }}
          </template>
        </Column>
        <Column
          field="CreationDate"
          header="Fecha Sistema"
          :sortable="true"
        >
          <template #body="slotProps">
            {{ slotProps.data.CreationDate }}
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="p-3">
            <h5>Productos para Nota de Salida - {{ slotProps.data.Code }}</h5>
            <DataTable :value="slotProps.data.Detail" responsiveLayout="scroll">
              <Column field="ProductCode" header="Codigo" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.ProductCode }}
                </template>
              </Column>
              <Column field="ProductName" header="Nombre" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.ProductName }}
                </template>
              </Column>
              <Column field="UnitMeassure" header="UM" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.UnitMeassure }}
                </template>
              </Column>
              <Column field="Quantity" header="Cantidad" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.Quantity }}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>
<script setup>
import moment from "moment";
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import ReportService from "@/service/ReportService";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";

const store = useStore();
const toast = useToast();
const products = ref([]);
const expandedRows = ref([]);
const productService = new ProductService();
const reportService = new ReportService();
const dt = ref(null);
const filters = ref({});
const isLoadingTable = ref(false);
const calendarValueBegin = ref("");
const calendarValueEnd = ref("");

let dateBeginAux;
let dateEndAux;
let dUser;
onBeforeMount(() => {
  dUser = JSON.parse(store.state.datauser);

  // let dateAux = moment();
  // let dateFormat = dateAux.format("YYYY-MM-DD");

  // isLoadingTable.value = true;

  // reportService
  //   .getReportNoteExit(dUser.User.Branch, "2000-01-01", dateFormat)
  //   .then((data) => {products.value = data.Data; isLoadingTable.value = false;});

  initFilters();
});

const expandAll = () => {
  if (products.value.length > 0) {
    expandedRows.value = products.value.filter((p) => p.IdNoteExit);
  }
};
const collapseAll = () => {
  expandedRows.value = null;
};

const formatCurrency = (value) => {
  return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

const exportCSV = () => {
  if (products.value.length > 0) {
    dt.value.exportCSV();
  }
};

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
};

const filterByDates = () => {
  if (validatesMsg() == "") {
    const allBranchOffice = -1;
    isLoadingTable.value = true;
    reportService
      .getReportNoteExit(
        allBranchOffice,
        dateBeginAux.format("YYYY-MM-DD"),
        dateEndAux.format("YYYY-MM-DD")
      )
      .then((data) => {
        products.value = data.Data;
        isLoadingTable.value = false;
      });
  } else {
    toast.add({
      severity: "error",
      summary: "Mensaje Error",
      detail: validatesMsg(),
      life: 3000,
    });
  }
};

const validatesMsg = () => {
  let res = "";
  if (calendarValueEnd.value == "") {
    res = "Debe ingresar una fecha de fin valida.";
  }
  if (calendarValueBegin.value == "") {
    res = "Debe ingresar una fecha de inicio valida.";
  }
  dateBeginAux = moment(calendarValueBegin.value);
  dateEndAux = moment(calendarValueEnd.value);

  if (dateBeginAux.diff(dateEndAux, "days") > 0) {
    res = "Le fecha de incio debe ser menor a la fecha de fin.";
  }

  return res;
};
</script>