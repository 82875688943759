<template>
    <div>
        <Dropdown 
            v-model="selectedOption" 
            :disabled="isComboSelected" 
            @change="$emit('valueOption', selectedOption.IdReasonExit)" 
            :options="options" 
            optionLabel="Name" 
            placeholder="Seleccione un motivo de salida"
            class="w-full" />
    </div>
</template>

<script setup>

import { ref,onMounted } from "vue";
import ReasonExitService from '@/service/ReasonExitService';

const reasonExitService = new ReasonExitService();

const options = ref(null);
const selectedOption = ref();

const props = defineProps(['code', 'isComboSelected'])

onMounted(() => { 
    reasonExitService.getReasonExit().then((data) => (options.value = data.Data));  
});

</script>