<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <div v-if="isLoadingScreen">
          <div class="progress-spinner-custom">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        </div>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Inactivar" icon="pi pi-power-off" class="p-button-danger" @click="deleteSelected"
                :disabled="!selectedItems || !selectedItems.length" />
            </div>
          </template>

          <template v-slot:end>
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="userList" v-model:selection="selectedItems" dataKey="IdUser" :paginator="true"
          :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50, 100]"
          currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} usuarios" responsiveLayout="scroll"
          :loading="isLoadingTable"
          class="p-datatable-sm">

          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Usuarios</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="Name" header="Nombre" :sortable="true" headerStyle="min-width:12rem;">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.FirstName + ' ' + slotProps.data.LastName  }}
            </template>
          </Column>
          <Column field="Login" header="Usuario" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Usuario</span>
              {{ slotProps.data.Login }}
            </template>
          </Column>
          <Column field="UserType" header="Tipo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Tipo</span>
              {{ slotProps.data.UserType }}
            </template>
          </Column>
          <Column field="CreationDate" header="Alta" :sortable="true" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Alta</span>
              {{ slotProps.data.CreationDate }}
            </template>
          </Column>
          <Column field="ExpiredDate" header="Expira" :sortable="true" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Expira</span>
              {{ slotProps.data.ExpiredDate }}
            </template>
          </Column>
          <Column field="Status" header="Estado" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Estado</span>
              <Tag :value="slotProps.data.Status? (slotProps.data.Expired? 'Expirado': 'Activo') : 'Inactivo'" 
                  :severity="slotProps.data.Status? (slotProps.data.Expired? 'danger': 'success')  : 'danger'" />
            </template>
          </Column>
          <Column field="CreatedBy" header="Usuario Alta" :sortable="true" headerStyle="min-width:12rem;">
            <template #body="slotProps">
              <span class="p-column-title">Usuario Alta</span>
              {{ slotProps.data.CreatedBy }}
            </template>
          </Column>
          <Column style="text-align: center;" headerStyle="min-width:12rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2"
                @click="onEdit(slotProps.data)" />
              <Button icon="pi pi-power-off" v-if="slotProps.data.Status" v-tooltip.top="'Inactivar'" class="p-button-rounded p-button-danger mt-2"
                @click="deleteIndividual(slotProps.data)" />
              <Button icon="pi pi-power-off" v-if="!slotProps.data.Status" v-tooltip.top="'Activar'" class="p-button-rounded p-button-warning mt-2"
                @click="deleteIndividual(slotProps.data)" />
              <Button icon="pi pi-user" v-tooltip.top="'Permisos'" class="p-button-rounded p-button-info ml-2"
                @click="openPermission(slotProps.data)" /> 
            </template>
          </Column> 
        </DataTable>

        <Dialog v-model:visible="isUserDialogOpen" :style="{ width: '480px' }" header="Detalle del Usuario" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="firstname">Nombres</label>
            <InputText id="firstname" v-model.trim="userModel.FirstName" :maxlength="150" required="true" autofocus autocomplete="off"
              :class="{ 'p-invalid': submitted && !userModel.FirstName }" />
            <small class="p-invalid" v-if="submitted && !userModel.FirstName">Nombre del usuario es requerido.</small>
          </div>
          <div class="field">
            <label for="name">Apellidos</label>
            <InputText id="name" v-model.trim="userModel.LastName" :maxlength="150" required="true" autofocus autocomplete="off"
              :class="{ 'p-invalid': submitted && !userModel.LastName }" />
            <small class="p-invalid" v-if="submitted && !userModel.LastName">Appelidos del usuario es requerido.</small>
          </div>
          <div class="field">
            <label for="documentnumber">DNI</label>
            <InputText id="documentnumber" v-model.trim="userModel.DocumentNumber" :maxlength="20" autofocus autocomplete="off"/>
          </div>
          <div class="field">
            <label for="emailv">Correo</label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-at"></i>
                </span>
                <InputText id="email" v-model.trim="userModel.Email" :maxlength="80" autocomplete="off"/>
            </div>
          </div>
          <div class="field">
            <label for="cellphone">Nro. contacto</label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-phone"></i>
                </span>
                <InputText id="cellphone" v-model.trim="userModel.CellPhone" :maxlength="9" autocomplete="off"/>
            </div>
          </div>
          <div class="field">
            <label for="user">Usuario de Acceso</label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                </span>
                <InputText id="user" v-model.trim="userModel.Login" :maxlength="50" autocomplete="off"/>
            </div>
          </div>
          <div class="field">
              <label for="usertype">Tipo de Usuario</label>
              <Dropdown id="usertype" v-model="userModel.UserType" :options="userTypeList" optionLabel="Name" placeholder="Selecciones tipo de usuario" />
          </div>
          <div class="field">
            <label for="expireddate">Fecha de Expiración</label>
            <Calendar id="expireddate" v-model="userModel.ExpiredDate" :showIcon="true" :showButtonBar="true" dateFormat="yy-mm-dd"></Calendar>
          </div>
          <!-- <div class="field">
              <label class="mb-3">Estado</label>
              <div class="formgrid grid">
                  <div class="field-radiobutton col-6">
                      <RadioButton id="active" name="status" :value="true" v-model="userModel.Status" />
                      <label for="active">Activo</label>
                  </div>
                  <div class="field-radiobutton col-6">
                      <RadioButton id="inactive" name="status" :value="false" v-model="userModel.Status" />
                      <label for="inactive">Inactivo</label>
                  </div>
              </div>
          </div> -->
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="onSave" />
          </template>
        </Dialog>

        <Dialog v-model:visible="isPermissionDialogOpen" :style="{ width: '480px' }" header="Administrar Permisos" :modal="true"
          class="p-fluid">
            <div class="flex justify-content-center">
              <Tree v-model:selectionKeys="selectedKey" v-model:expandedKeys="expandedKeys" :value="nodes" selectionMode="checkbox" 
              class="w-full md:w-30rem" @nodeSelect="onNodeSelect" @nodeUnselect="onNodeUnselect" :loading="isLoadingTree"></Tree>
            </div>
          <template #footer>
            <Button label="Cancelar" v-if="!isLoadingTree" icon="pi pi-times" class="p-button-text" @click="hidePermissionDialog" />
            <Button label="Guardar" v-if="!isLoadingTree" icon="pi pi-check" class="p-button-text" @click="onSavePermission" />
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>
  
<script setup>
import { FilterMatchMode } from 'primevue/api';
import { ref, onMounted, onBeforeMount } from 'vue';
import UserService from '@/service/UserService' ;
import PermissionService from '@/service/PermissionService' ;
import { useToast } from 'primevue/usetoast';
import { useConfirm } from "primevue/useconfirm";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const confirm = useConfirm();
const toast = useToast();

const userList = ref(null);
const userTypeList = ref(null);
const isUserDialogOpen = ref(false);
const isPermissionDialogOpen = ref(false);
const isLoadingTable = ref(false);
const userModel = ref({});

const selectedItems = ref(null);
const dt = ref(null);
const filters = ref({});
const submitted = ref(false);
const isLoadingScreen = ref(false);

const userService = new UserService();
const permissionService = new PermissionService();
const nodes = ref(null);
const selectedKey  = ref({});
const expandedKeys = ref({});
const isLoadingTree = ref(false);
const userModelPermission = ref({});


onBeforeMount(() => {
  initFilters();
});
onMounted(() => {
  loadData();
});

const loadGridList = () => {
  isLoadingTable.value = true;
  userService.getUserAll().then(response => {
      userList.value = response.Data;
      isLoadingTable.value = false;
  });
  userService.getUserTypeAll().then(response => {
      userTypeList.value = response.Data;
  });
}

const loadData = () => {
  loadGridList();
}

const openNew = () => {
  userModel.value = {};
  submitted.value = false;
  isUserDialogOpen.value = true;
};

const hideDialog = () => {
  isUserDialogOpen.value = false;
  submitted.value = false;
  userModel.value = {};
};


const onNodeSelect = (node) => {
    //toast.add({ severity: 'success', summary: 'Node Selected', detail: node.label, life: 3000 });
    //console.log(JSON.stringify(selectedKey.value))
};

const onNodeUnselect = (node) => {
   //toast.add({ severity: 'success', summary: 'Node Unselected', detail: node.label, life: 3000 });
   //console.log(JSON.stringify(selectedKey.value))
};

const openPermission = (model) => { 
  userModelPermission.value = model
  selectedKey.value = []
  expandedKeys.value = []
  isLoadingTree.value = true;
  nodes.value = null
  isPermissionDialogOpen.value = true;
  

  const request = { 
    idUser: model.IdUser, 
  };

  permissionService.get(request.idUser).then(response => {
    if(response.Status == 200){
      isLoadingTree.value = false;
      nodes.value = response.Data.data;
      expandAll();
      setSelectedItems();
      //toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
    }else{
      isLoadingTree.value = false;
      toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
    }});
};

const expandAll = () => {
    for (let node of nodes.value) {
        expandNode(node);
    }
    expandedKeys.value = { ...expandedKeys.value };
};

const expandNode = (node) => {
    if (node.children && node.children.length) {
        expandedKeys.value[node.key] = true;

        for (let child of node.children) {
            expandNode(child);
        }
    }
};


const setSelectedItems = () => {

  for (let node of nodes.value) {
    checkedRecursive(node);
  }
    selectedKey.value = { ...selectedKey.value };
}

const checkedRecursive = (node) => {
  if(node.flag){
    selectedKey.value[node.key] = { checked: true,partialChecked:false};
  }
  if(node.partialSelected){
    selectedKey.value[node.key] = { checked: false,partialChecked:true};
  }
  if(node.children){
    for (let child of node.children) {
      checkedRecursive(child);
    }
  }
}

const hidePermissionDialog = () => {
  isPermissionDialogOpen.value = false;
};

const onSavePermission = () => {
  const userEdit = userModelPermission.value 
  const dUser = JSON.parse(store.state.datauser);

  let auxSelect = selectedKey.value
  var result = [];

  for(var i in auxSelect)
      result.push([i,auxSelect[i]]);
  
  let arrAux = []
  for(var it in result){
    let temp = {}
    temp["IdMenu"] = Number(calculateLastId(result[it][0]))
    temp["PartialSelected"] = result[it][1].partialChecked
    temp["Flag"] = result[it][1].checked
    arrAux.push(temp);
  }
  console.log("arrAux")
  console.log(arrAux)

  const request = {
    IdUser: userEdit.IdUser,
    userCreate: dUser.User.IdUser,
    Items: arrAux
  }


  if(dUser.User.IdUser == userEdit.IdUser){
    confirm.require({
    message: 'El usuario de inicio de sesión actual corresponde al usuario que se está modificando. Se terminará la sesión actual. ¿Está seguro de guardar los cambios?',
    header: 'Confirmación',
    icon: 'pi pi-info-circle',
    accept: async () => {      
        createPermissions(request);      
        closeSesion();
    }
  });
  }else{
    createPermissions(request);
  }
}

const closeSesion = () => {
  store.commit("logout");
  router.push({ path: "/" });
};

const createPermissions = (request) => {
  isLoadingTree.value = true;
  permissionService.addPermissions(request).then(response => {
    if(response.Status == 201){
      isLoadingTree.value = false;
      isPermissionDialogOpen.value = false;
      //console.log(response.Data.data);
      toast.add({ severity: 'success', summary: 'Éxito', detail: "Permisos asignados correctamente.", life: 3000 });
    }else{
      isLoadingTree.value = false;
      toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
    }});
} 

const calculateLastId = (cadena) => {
  let result ;
  var arrayDeCadenas = []
  //if(cadena.indexOf("-") == 1){
  if(cadena.includes("-")){
    // split
    arrayDeCadenas = cadena.split("-");
    result = arrayDeCadenas[arrayDeCadenas.length - 1];
  }else{
    result = cadena;
  }
  return result
}

const onSave = () => {
  submitted.value = true;
  const { ...model } = { ...userModel.value };
  if(!model?.FirstName){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor ingrese el nombre del usuario.", life: 3000 });
    return;
  }
  if(!model?.LastName){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor ingrese los apellidos del usuario.", life: 3000 });
    return;
  }
  if(!model?.Login){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor ingrese un nombre de usuario para el acceso al sistema.", life: 3000 });
    return;
  }
  if(!model?.UserType){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor seleccione un tipo de usuario", life: 3000 });
    return;
  }
  if(!model?.ExpiredDate){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor seleccione una fecha de expiración para el usuario", life: 3000 });
    return;
  }


  if(!model.DocumentNumber) model.DocumentNumber = '';
  if(!model.Email) model.Email = '';
  if(!model.CellPhone) model.CellPhone = '';
  if(!model.CellPhone) model.CellPhone = '';

  const { IdUserType } = model.UserType;
  const dUser = JSON.parse(store.state.datauser);
  const request = { 
    CreationUser: dUser.User.IdUser, 
    IdUserType,
    Action: (model?.IdUser?? 0)> 0 ? 'U' : 'N', 
    ...model
  };

  //console.log(request)
  confirm.require({
    message: '¿Está seguro de guardar los cambios?',
    header: 'Confirmación',
    icon: 'pi pi-info-circle',
    accept: async () => {
      if(request.Action == 'N'){
        createModel(request);
      }else{
        updateModel(request);
      }
    }
  });
};

const createModel = (request) => {
  isUserDialogOpen.value = false;
  isLoadingScreen.value = true;
  userService.create(request).then(response => {
    if(response.Status == 201){
      toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
    }else{
      toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
    }
    submitted.value = false;
    isLoadingScreen.value = false;
    loadGridList();
  });
}

const updateModel = (request) => {
  isUserDialogOpen.value = false;
  isLoadingScreen.value = true;
  userService.update(request).then(response => {
    if(response.Status == 200){
      toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
    }else{
      toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
    }
    submitted.value = false;
    isLoadingScreen.value = false;
    loadGridList();
  });
}


const onEdit = (model) => {
  const userType = userTypeList.value.find(u => u.Name == model.UserType);
  userModel.value =  {...model, UserType: userType, ExpiredDate: new Date(model.ExpiredDate + ' 00:00:00') };
  isUserDialogOpen.value = true;
};


const deleteIndividual = ({ IdUser, Status }) => {
  const confirmMessage = Status ? '¿Está seguro de inactivar este usuario?' : '¿Está seguro de activar este usuario?'
  const confirmBtnClass = Status ? 'p-button-danger' : 'p-button-sucess'
  confirm.require({
    message: confirmMessage,
    header: 'Confirmación',
    icon: 'pi pi-info-circle',
    acceptClass: confirmBtnClass,
    accept: async () => {
      isLoadingScreen.value = true;
      const dUser = JSON.parse(store.state.datauser);
      const idUserAction = dUser.User.IdUser;
      userService.delete(IdUser, idUserAction).then(response => {
        if(response.Status == 200){
          console.log("");
          const severityClass = Status ? 'warn' : 'success'
          toast.add({ severity: severityClass, summary: 'Éxito', detail: response.Message, life: 3000 });
        }else{
          toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
        }
        isLoadingScreen.value = false;
        loadGridList();
      });
    }
  });
};
  
const exportCSV = () => {
  dt.value.exportCSV();
};


const deleteSelected = () => {
  confirm.require({
        message: '¿Está seguro de inactivar todos las usuarios seleccionadas?',
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
          isLoadingScreen.value = true;
          const dUser = JSON.parse(store.state.datauser);
          const idUserAction = dUser.User.IdUser;
          for (var i = 0; i < selectedItems.value.length; i++) {
            const { IdUser } = selectedItems.value[i];
            await userService.delete(IdUser, idUserAction);
          }
          isLoadingScreen.value = false;
          toast.add({ severity: 'success', summary: 'Éxito', detail: "Registros inactivados de manera exitosa.", life: 3000 });
          loadGridList();
        }
    });
};

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  };
};

</script>
  
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';
.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}
</style>
