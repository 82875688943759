<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start> </template>

          <template v-slot:end>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="stockProducts"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50, 100]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} stock de productos"
          responsiveLayout="scroll"
          :loading="isLoadingTable"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0">Stock de Productos</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>

          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
          <Column
            field="CodeCoptos"
            header="Código"
            :sortable="true"
            headerStyle="min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              {{ slotProps.data.CodeCoptos }}
            </template>
          </Column>          
          <Column
            field="Name"
            header="Nombre"
            :sortable="true"
            headerStyle="min-width:16rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.Name }}
            </template>
          </Column>
          <Column
            field="UnitMeassure"
            header="UM"
            :sortable="true"
            headerStyle="min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">UM</span>
              {{ slotProps.data.UnitMeassure }}
            </template>
          </Column>
          <Column
            field="Status"
            header="Estado"
            :sortable="true"
            headerStyle="min-width:5rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Estado</span>
              <span
                :class="
                  'product-badge-custom status-' +
                  (slotProps.data.Status
                    ? slotProps.data.Status.toLowerCase()
                    : '')
                "
              >
                {{ slotProps.data.Status }}</span
              >
            </template>
          </Column>
          <Column
            field="Stock"
            header="Stock"
            :sortable="true"
            headerStyle="min-width:5rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Stock</span>
              {{ slotProps.data.Stock }}
            </template>
          </Column>
          <Column
            field="StockAverage"
            header="Stock bajo"
            :sortable="true"
            headerStyle="min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Stock bajo</span>
              {{ slotProps.data.StockAverage }}
            </template>
          </Column>
          <Column
            field="StockMinimum"
            header="Agotándose"
            :sortable="true"
            headerStyle="min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Agotándose</span>
              {{ slotProps.data.StockMinimum }}
            </template>
          </Column>
          <Column
            field="StockMaximum"
            header="Máximo"
            :sortable="true"
            headerStyle="min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Máximo</span>
              {{ slotProps.data.StockMaximum }}
            </template>
          </Column>
          <!-- <Column
            field="Category"
            header="Categoria"
            :sortable="true"
            headerStyle="width:14%; min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Categoria</span>
              {{ slotProps.data.Category }}
            </template>
          </Column>
          <Column
            field="UnitMeassure"
            header="Und. Medida"
            :sortable="true"
            headerStyle="width:14%; min-width:8rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Und. Medida</span>
              {{ slotProps.data.UnitMeassure }}
            </template>
          </Column>
          <Column
            field="Supplier"
            header="Proveedor"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Proveedor</span>
              {{ slotProps.data.Supplier }}
            </template>
          </Column> -->

          <!-- <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column> -->
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script setup>
import { FilterMatchMode } from "primevue/api";
import { ref, onMounted, onBeforeMount } from "vue";
import ReportService from "@/service/ReportService";
import { useStore } from "vuex";

const store = useStore();
const reportService = new ReportService();

const filters = ref({});
const stockProducts = ref(null);
const isLoadingTable = ref(false);
const dt = ref(null);

onBeforeMount(() => {
  initFilters();
});

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };
};

onMounted(() => {
  loadData();
});

const loadData = async () => {
  let dUser = JSON.parse(store.state.datauser);

  isLoadingTable.value = true;
  let dataRes = await reportService.getReportStock(dUser.User.Branch);
  isLoadingTable.value = false;
  console.log("JSON.stringify(dataRes)");
  //console.log(JSON.stringify(dataRes));

  dataRes.Data.forEach(function (element) {
    element["id"] = element.IdProduct;
    element["Status"] = "";
    if (element.StockCode == "SUCCESS") {
      element.Status = "en-stock";
    }
    if (element.StockCode == "AVERAGE") {
      element.Status = "stock-bajo";
    }
    if (element.StockCode == "MINIMUM") {
      element.Status = "agotandose";
    }
    if (element.StockCode == "MAXIMUM") {
      element.Status = "maximo";
    }
    if (element.StockCode == "CERO") {
      element.Status = "sin-stock";
    }
  });

  stockProducts.value = dataRes.Data;
};

const exportCSV = () => {
  dt.value.exportCSV();
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/styles/badges.scss";
.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}
</style>