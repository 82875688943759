export default class ReportService {   

    async getReportStock(idBranchOffice){
        const response  = await fetch(process.env.VUE_APP_API_IOT + '/v1/report/stock-products/' + idBranchOffice,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        return response.json();
    }


    getReportNoteEntry(idBranchOffice,dateBegin, dateEnd) {
        return fetch(process.env.VUE_APP_API_IOT + '/v1/report/notes-entry/' + idBranchOffice + '/' + dateBegin + '/' + dateEnd)
            .then((res) => res.json())

    }
    getReportNoteExit(idBranchOffice,dateBegin, dateEnd) {
        return fetch(process.env.VUE_APP_API_IOT + '/v1/report/notes-exit/' + idBranchOffice + '/' + dateBegin + '/' + dateEnd)
            .then((res) => res.json())

    }
    
}