export default class UnitMeasureService {

    async getUnitMeasureAll() {
        const res = await fetch(process.env.VUE_APP_API_IOT + '/v1/unit-measure');
        return await res.json();
    }

    async create(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/unit-measure', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async update(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/unit-measure', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async delete(id, idUserAction) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/unit-measure/' + id + '/' + idUserAction, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });

        return response.json();
    }

}