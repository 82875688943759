import { createRouter, createWebHistory } from 'vue-router'
import NoteEntry from '../components/NoteEntry/NoteEntry'
import NoteExit from '../components/NoteExit/NoteExit'
import ReportStock from '../components/Reports/ReportStock/ReportStock'
import ReportNoteEntry from '../components/Reports/ReportNoteEntry/ReportNoteEntry'
import ReportNoteExit from '../components/Reports/ReportNoteExit/ReportNoteExit'
import PasswordChangeView from '../views/PasswordChangeView'
import DashboardView from '../views/DashboardView.vue'
import PageNotFoundView from '../views/PageNotFoundView'
import InitView from '../views/InitView'
import AppLayout from '@/layout/AppLayout.vue';
import LoginView from '../views/LoginView';
import LandingView from '../views/LandingView';
import ModulesView from '../views/ModulesView';
import MaintenanceProduct from '../components/Product/Maintenance'
import MaintenanceSupplier from '../components/Supplier/Maintenance'
import MaintenanceCategory from '../components/Category/Maintenance'
import MaintenanceUnitMeasure from '../components/UnitMeasure/Maintenance'
import MaintenanceUser from '../components/User/Maintenance'
import MaintenanceUserType from '../components/UserType/Maintenance'
import MaintenanceBranchOffice from "../components/BranchOffice/Maintenance";
import { useStore } from "vuex";

const routes = [{
        path: '/',
        component: LandingView
    },
    {
        path: '/login',
        component: LoginView,
        name: 'Login'
    },
    {
        path: '/system',
        component: AppLayout,
        children: [{
                path: '/system',
                component: InitView,
                name: "Init",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system-dashboard',
                component: DashboardView,
                name: "Dashboard",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/modules',
                component: ModulesView,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/product',
                component: MaintenanceProduct,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/supplier',
                component: MaintenanceSupplier,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/note-entry',
                component: NoteEntry,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/note-exit',
                component: NoteExit,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/report-stock',
                component: ReportStock,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/change-password',
                component: PasswordChangeView,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/category',
                component: MaintenanceCategory,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/unit-measure',
                component: MaintenanceUnitMeasure,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/report-note-entry',
                component: ReportNoteEntry,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/report-note-exit',
                component: ReportNoteExit,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/user',
                component: MaintenanceUser,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/usertype',
                component: MaintenanceUserType,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
              path: '/system/branch-office',
              component: MaintenanceBranchOffice,
              meta: {
                requiresAuthentication: true,
              },
            },
            {
                path: "*",
                component: PageNotFoundView,
                // meta: {
                //   requiresAuthentication: true,
                // }
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let store = useStore();
  let dUser = JSON.parse(store.getters["isAuthenticated"]);
  const isAuthenticated = dUser;

  if (from.name !== "Login") {
    if (to.matched.some((record) => record.meta.requiresAuthentication)) {
      if (isAuthenticated) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else {
      if (isAuthenticated && to.meta.disallowAuthed) {
        next({ name: "Init" });
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
