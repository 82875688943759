<script setup>
import { onMounted, ref } from "vue";
import UserService from "@/service/UserService";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();
const toast = useToast();

const idUsername = ref(0);
const isLoadinga = ref(false);
const selectedRecommendations = ref();
const recommendations = ref([
  { name: "Una mayúscula", code: "NY" },
  { name: "Una minúscula", code: "RM" },
  { name: "Un número", code: "LDN" },
  { name: "Un caracter especial [-,$,_,#,.]", code: "IST" },
  { name: "10 caracteres mínimo", code: "PRS" },
]);

const currentPassword = ref("");
const newPassword = ref("");
const confirmNewPassword = ref("");
const response = ref(null);

const userService = new UserService();

onMounted(() => {
  const idUser = JSON.parse(store.state.datauser);

  idUsername.value = idUser.User.IdUser;
});



const updatePassword = async () => {

  try {
    isLoadinga.value = true;
    if (newPassword.value !== confirmNewPassword.value) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "La nueva contraseña y su confirmación no coinciden.",
        life: 3000,
      });
      return;
    }
    
    const request = { 
      IdUsername :idUsername.value,
      ConfirmNewPassword: confirmNewPassword.value,
      CurrentPassword: currentPassword.value
  };
  console.log(request);
    response.value = await userService.updatePassword(
      request
    );
    console.log(response);
    console.log(response.value.Status);
    console.log(response.value.Message);
    if (response.value.Status == 200) {
      isLoadinga.value = false;
      toast.add({
      severity: "success",
      summary: "Éxito",
      detail: "Cambio de contraseña exitoso.",
      life: 3000,
    });

      resetInputs();
      return;
    }
    isLoadinga.value = false;
    console.log('ingreso 1');
        toast.add({
          severity: "error",
          summary: "Error",
          detail: response.value.Message,
          life: 3000,
        });

    resetInputs();
    //router.push({ path: "/system" });
  } catch (error) {
    console.log(error);
  } finally {
    isLoadinga.value = false;
  }
};
const resetInputs = () => {
  currentPassword.value = "";
  confirmNewPassword.value = "";
  newPassword.value = "";
};
</script>

<template>
  <div class="grid">
    <Toast />
    <div v-if="isLoadinga">
      <div class="progress-spinner-custom">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card p-fluid">
        <h5>Modifica tu contraseña</h5>
        <div class="field">
          <label for="name1">Contraseña actual*</label>
          <Password
            v-model="currentPassword"
            toggleMask
            class="w-full mb-3"
            inputClass="w-full"
            inputStyle="padding:1rem"
          />
        </div>
        <div class="field">
          <label for="email1">Nueva contraseña*</label>
          <Password
            v-model="newPassword"
            toggleMask
            class="w-full mb-3"
            inputClass="w-full"
            inputStyle="padding:1rem"
          />
        </div>
        <div class="field">
          <label for="age1">Confirmar nueva contraseña*</label>
          <Password
            v-model="confirmNewPassword"
            toggleMask
            class="w-full mb-3"
            inputClass="w-full"
            inputStyle="padding:1rem"
          />
        </div>
        <Button
          label="Cancelar"
          class="p-button-rounded p-button-danger mr-2 mb-2"
          @click="resetInputs"
        />
        <Button
          label="Guardar cambios"
          class="p-button-rounded mr-2 mb-2"
          @click="updatePassword"
        />
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card p-fluid">
        <h6>Recuerda usar los siguientes elementos</h6>
        <div class="formgrid grid">
          <Listbox
            v-model="selectedRecommendations"
            :options="recommendations"
            optionLabel="name"
            class="w-full md:w-24rem"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}

.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}
</style>