<template>
    <div class="grid">

      <div class="col-12 md:col-3">
        <Card>
                <template v-slot:title>
                    <div class="flex align-items-center justify-content-between mb-0">
                        <h5>Card</h5>
                    </div>
                </template>

                <template v-slot:content>
                    <p class="line-height-3 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </p>
                </template>
            </Card>
      </div>

      <div class="col-12 md:col-3">
        <Card>
                <template v-slot:title>
                    <div class="flex align-items-center justify-content-between mb-0">
                        <h5>Card</h5>
                    </div>
                </template>

                <template v-slot:content>
                    <p class="line-height-3 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </p>
                </template>
            </Card>
      </div>

      <div class="col-12 md:col-3">
        <Card>
                <template v-slot:title>
                    <div class="flex align-items-center justify-content-between mb-0">
                        <h5>Card</h5>
                    </div>
                </template>

                <template v-slot:content>
                    <p class="line-height-3 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </p>
                </template>
            </Card>
      </div>

      <div class="col-12 md:col-3">
        <Card>
                <template v-slot:title>
                    <div class="flex align-items-center justify-content-between mb-0">
                        <h5>Card</h5>
                    </div>
                </template>

                <template v-slot:content>
                    <p class="line-height-3 m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </p>
                </template>
            </Card>
      </div>

    </div>
  </template>
  <script>
 
  export default {
    name: 'ModulesView',
    
  }
  </script>
  