export default class UserService {

    async login(user, pass) {

        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/security/access/${user}/${pass}`, {
            method: "GET"
        });

        return response.json();

    }

    async getUserAll() {
        const res = await fetch(process.env.VUE_APP_API_IOT + '/v1/user');
        return await res.json();
    }

    async getUserTypeAll() {
        const res = await fetch(process.env.VUE_APP_API_IOT + '/v1/user-type');
        return await res.json();
    }

    async create(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/user', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async updatePassword(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/user/updatepassword', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async delete(id, idUserAction) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/user/' + id + '/' + idUserAction, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });

        return response.json();
    }
}