<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <div v-if="isLoadingScreen">
          <div class="progress-spinner-custom">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        </div>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="deleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length" />
            </div>
          </template>

          <template v-slot:end>
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="productList" v-model:selection="selectedProducts" dataKey="IdProduct" :paginator="true"
          :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50, 100]"
          currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} productos" responsiveLayout="scroll"
          :loading="isLoadingTable"
          class="p-datatable-sm">

          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Productos</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="CodeCoptos" header="Código" :sortable="true" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              {{ slotProps.data.CodeCoptos }}
            </template>
          </Column>
          <Column field="Name" header="Nombre" :sortable="true" headerStyle="min-width:16rem;">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.Name }}
            </template>
          </Column>
          <Column field="Model" header="Modelo" :sortable="true" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Modelo</span>
              {{ slotProps.data.Model }}
            </template>
          </Column>
          <Column field="Category" header="Categoría" :sortable="true" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Categoría</span>
              {{ slotProps.data.Category }}
            </template>
          </Column>
          <Column field="UnitMeassure" header="UM" :sortable="true" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">UM</span>
              {{ slotProps.data.UnitMeassure }}
            </template>
          </Column>
          <Column field="Stock" header="Stock" :sortable="true" headerStyle="min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Stock</span>
              {{ slotProps.data.Stock }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="deleteIndividual(slotProps.data)" />
            </template>
          </Column> 
        </DataTable>

        <Dialog v-model:visible="isProductDialogOpen" :style="{ width: '480px' }" header="Detalle del Producto" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="name">Nombre</label>
            <InputText id="name" v-model.trim="productModel.Name" required="true" autofocus autocomplete="off"
              :class="{ 'p-invalid': submitted && !productModel.Name }" />
            <small class="p-invalid" v-if="submitted && !productModel.Name">Nombre es requerido.</small>
          </div>
          <div class="field">
            <label for="commercialname">Nombre comercial</label>
            <InputText id="commercialname" class="p-invalid" v-model.trim="productModel.CommercialName" required="true" autocomplete="off"/>
          </div>
          <div class="field">
          <label for="supplier">Proveedor</label>
            <Dropdown v-model="productModel.Supplier" :options="supplierList" filter optionLabel="ReasonSocial" placeholder="Seleccione un proveedor">
                <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">                      
                        <div>{{ slotProps.value.ReasonSocial }}</div>
                    </div>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ slotProps.option.ReasonSocial }}</div>
                    </div>
                </template>
            </Dropdown>
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <label for="model">Modelo</label>
              <InputText id="model" v-model.trim="productModel.Model" required="false" autocomplete="off"/>
            </div>
            <div class="field col">
              <label for="content">Contenido</label>
              <InputText id="content" v-model.trim="productModel.Content" required="false" autocomplete="off"/>
            </div>
          </div>
          <div class="field">
            <label for="category">Categoría</label>
            <Dropdown v-model="productModel.Category" :options="categoryList" filter optionLabel="Name" placeholder="Seleccione una categoría">
                <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">                      
                        <div>{{ slotProps.value.Name }}</div>
                    </div>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ slotProps.option.Name }}</div>
                    </div>
                </template>
            </Dropdown>
          </div>
          <div class="field">
          <label for="category">Unidad de medida</label>
            <Dropdown v-model="productModel.UnitMeasure" :options="unitMeasureList" filter optionLabel="Description" placeholder="Seleccione una unidad de medida">
                <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">                      
                        <div>{{ slotProps.value.Description }}</div>
                    </div>
                    <span v-else>
                        {{ slotProps.placeholder }}
                    </span>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ slotProps.option.Description }}</div>
                    </div>
                </template>
            </Dropdown>
          </div>
          <div class="formgrid grid">
            <!-- <div class="field col">
              <label for="task">Vida util</label>
              <InputNumber id="task" v-model.trim="productModel.Task" required="false"/>
            </div> -->            
            <div class="field col">
              <label for="stock">Stock</label>
              <InputNumber id="stock" v-model.trim="productModel.Stock" showButtons :min="0" required="true"/>
            </div>
            <div class="field col">
              <label for="StockMaximum">Máximo</label>
              <InputNumber id="StockMaximum" v-model.trim="productModel.StockMaximum" showButtons :min="0" required="false"/>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <label for="stockaverage">Stock bajo</label>
              <InputNumber id="stockaverage" v-model.trim="productModel.StockAverage" showButtons :min="0" required="false"/>
            </div>
            <div class="field col">
              <label for="stockminimum">Agotándose</label>
              <InputNumber id="stockminimum" v-model.trim="productModel.StockMinimum" showButtons :min="0" required="false"/>
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { FilterMatchMode } from 'primevue/api';
import { ref, onMounted, onBeforeMount } from 'vue';
import ProductService from '@/service/ProductService' ;
import CategoryService from '@/service/CategoryService' ;
import SupplierService from '@/service/SupplierService' ;
import UnitMeasureService from '@/service/UnitMeasureService' ;
import { useToast } from 'primevue/usetoast';
import { useConfirm } from "primevue/useconfirm";
import { useStore } from "vuex";

const store = useStore();
const confirm = useConfirm();
const toast = useToast();

const productList = ref(null);
const categoryList = ref(null);
const supplierList = ref(null);
const unitMeasureList = ref(null);
const isProductDialogOpen = ref(false);
const isLoadingTable = ref(false);
const productModel = ref({});

const selectedProducts = ref(null);
const dt = ref(null);
const filters = ref({});
const submitted = ref(false);
const isLoadingScreen = ref(false);

const productService = new ProductService();
const categoryService = new CategoryService();
const supplierService = new SupplierService();
const unitMeasureService = new UnitMeasureService();

onBeforeMount(() => {
  initFilters();
});
onMounted(() => {
  loadData();
});

const loadProductList = () => {
  isLoadingTable.value = true;
  productService.getProductsByBranchOffice(7).then(response => {
    productList.value = response.Data;
    isLoadingTable.value = false;
  });
}

const loadData = () => {
  loadProductList();
  categoryService.getCategoryAll().then(response => categoryList.value = response.Data);
  supplierService.getSupplierAll().then(response => supplierList.value = response.Data);
  unitMeasureService.getUnitMeasureAll().then(response => unitMeasureList.value = response.Data);
}

const openNew = () => {
  productModel.value = {};
  submitted.value = false;
  isProductDialogOpen.value = true;
};

const hideDialog = () => {
  isProductDialogOpen.value = false;
  submitted.value = false;
  productModel.value = {};
};

const saveProduct = () => {
  submitted.value = true;
  const { Category, Supplier, UnitMeasure, ...model } = { ...productModel.value };
  if(!model?.Name){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor ingrese el nombre del producto.", life: 3000 });
    return;
  }
  if(!Supplier){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor seleccione un proveedor.", life: 3000 });
    return;
  }
  if(!Category){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor seleccione una categoría.", life: 3000 });
    return;
  }
  if(!UnitMeasure){
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: "Por favor seleccione una unidad de medida.", life: 3000 });
    return;
  }

  if(!model.Task) model.Task = 0;
  if(!model.Stock) model.Stock = 0;
  if(!model.StockAverage) model.StockAverage = 0;
  if(!model.StockMinimum) model.StockMinimum = 0;
  if(!model.StockMaximum) model.StockMaximum = 0;
  if(!model.CommercialName) model.CommercialName = '';
  if(!model.Model) model.Model = '';
  if(!model.Content) model.Content = '';


  const { IdCategory } = Category;
  const { IdSupplier } = Supplier;
  const { IdUnitMeasure } = UnitMeasure;

  const dUser = JSON.parse(store.state.datauser);

  const request = { 
    IdCategory,  
    IdSupplier, 
    IdUnitMeasure, 
    IdBranchOffice: dUser.User.Branch, 
    CreationUser: dUser.User.IdUser, 
    Action: (model?.IdProduct?? 0) > 0 ? 'U' : 'N', 
    ...model
  };

  confirm.require({
    message: '¿Está seguro de guardar los cambios?',
    header: 'Confirmación',
    icon: 'pi pi-info-circle',
    accept: async () => {
      if(request.Action == 'N'){
        createProduct(request);
      }else{
        updateProduct(request);
      }
    }
  });
};

const createProduct = (request) => {
  isProductDialogOpen.value = false;
  isLoadingScreen.value = true;
  productService.create(request).then(response => {
    if(response.Status == 201){
      toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
    }else{
      toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
    }
    submitted.value = false;
    isLoadingScreen.value = false;
    loadProductList();
  });
}

const updateProduct = (request) => {
  isProductDialogOpen.value = false;
  isLoadingScreen.value = true;
  productService.update(request).then(response => {
    if(response.Status == 200){
      toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
    }else{
      toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
    }
    submitted.value = false;
    isLoadingScreen.value = false;
    loadProductList();
  });
}


const editProduct = ({ IdProduct }) => {
  isLoadingScreen.value = true;
  const dUser = JSON.parse(store.state.datauser);
  const idBranchOffice = dUser.User.Branch;
  productModel.value = {};
  productService.getProductsById(IdProduct, idBranchOffice).then(response => {
    buildProductModel(response.Data);
    isProductDialogOpen.value = true;
    isLoadingScreen.value = false;
  })
};

const buildProductModel = (data) => {
    const { IdCategory, IdSupplier, IdUnitMeasure, ...product } = data;
    const Category = categoryList.value?.find(c => c.IdCategory == IdCategory);
    const Supplier = supplierList.value?.find(c => c.IdSupplier == IdSupplier);
    const UnitMeasure = unitMeasureList.value?.find(c => c.IdUnitMeasure == IdUnitMeasure);
    productModel.value = { Category, Supplier, UnitMeasure, ...product}
}


const deleteIndividual = ({ IdProduct }) => {
  confirm.require({
    message: '¿Está seguro de eliminar este producto?',
    header: 'Confirmación',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger',
    accept: async () => {
      isLoadingScreen.value = true;
      const dUser = JSON.parse(store.state.datauser);
      const idUserAction = dUser.User.IdUser;
      productService.delete(IdProduct, idUserAction).then(response => {
        if(response.Status == 200){
          toast.add({ severity: 'success', summary: 'Éxito', detail: response.Message, life: 3000 });
        }else{
          toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000 });
        }
        isLoadingScreen.value = false;
        loadProductList();
      });
    }
  });
};



const exportCSV = () => {
  dt.value.exportCSV();
};


const deleteSelected = () => {
  confirm.require({
        message: '¿Está seguro de eliminar todos los productos seleccionados?',
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
          isLoadingScreen.value = true;
          const dUser = JSON.parse(store.state.datauser);
          const idUserAction = dUser.User.IdUser;
          for (var i = 0; i < selectedProducts.value.length; i++) {
            const { IdProduct } = selectedProducts.value[i];
            await productService.delete(IdProduct, idUserAction);
          }
          isLoadingScreen.value = false;
          toast.add({ severity: 'success', summary: 'Éxito', detail: "Registros eliminados de manera exitosa.", life: 3000 });
          loadProductList();
        }
    });
};

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  };
};

</script>
  
<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';
.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}
</style>
